import {
  Button as MuiButton,
  Table as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@material-ui/core'

import { TableContainer as MuiTableContainer } from '@material-ui/core'
import { colors } from '@monorepo/theme'
import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const TableContainer = styled(MuiTableContainer)`
  &.MuiTableContainer-root {
    overflow-y: hidden;
  }
`

export const Table = styled(MuiTable)`
  background-color: ${colors.white};
`

export const TableHead = styled(MuiTableHead)`
  position: relative;

  &::after {
    line-height: 4px;
    content: '.';
    color: transparent;
    background-color: #f3f6f8;
    width: 100%;
    position: absolute;
  }
`

export const TableRow = styled(MuiTableRow)`
  cursor: pointer;
`

export const TableHeadCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    color: ${colors.slateGrey};
    font-family: PFDINText;
    font-size: 12px;
    text-transform: uppercase;
    height: 48px;
    padding: 0 16px;
  }
`

export const TableCellTitle = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-weight: bold;
    border-bottom: 2px solid gray;
  }
`

export const TableCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    padding: 0 5px 0 5px;
    border-bottom: 2px solid gray;
  }
`

export const Button = styled(MuiButton).attrs({ disableRipple: true })`
  &.MuiButton-root {
    border: 2px solid ${colors.grey};
  }

  .MuiButton-label {
    color: ${colors.grey};
    font-size: 0.9em;
    font-family: 'ATTAleckSansBold';
    text-transform: none;
  }
`

export const TbodyRowLoading = styled(TableRow)`
  height: 50px;
  position: relative;
`

export const TbodyRow = styled(TableRow)`
  border-bottom: 4px solid #f3f6f8;
  height: 139px;

  & > .MuiTableCell-root:first-child {
    width: 152px;
  }
`

export const EventTitle = styled.p`
  font-family: PFDINText;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: ${colors.black};
  max-width: 285px;
`

export const StatusTag = styled.div`
  border-radius: 20px;
  height: 30px;
  width: 70px;
  font-family: PFDINText;
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;

  &:not([data-status]) {
    background-color: ${colors.highlightGrey};
  }

  &[data-status='1'] {
    background-color: ${colors.green};
  }

  &[data-status='2'] {
    background-color: ${colors.orange};
  }

  &[data-status='3'] {
    background-color: ${colors.red};
  }

  &[data-status='4'] {
    background-color: ${colors.blue};
  }

  &[data-status='5'] {
    background-color: ${colors.purple};
  }

  &[data-status='8'] {
    background-color: ${colors.grey};
  }
`

export const TBodyCell = styled(MuiTableCell)`
  &.MuiTableCell-root {
    font-family: PFDINText;
    font-size: 14px;
    padding: 8px;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row-wrap;
  gap: 8px;
`

export const TBodyCellLoading = styled(MuiTableCell)`
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: none;

  &.MuiTableCell-root {
    padding: 0;
    display: flex;
  }
`

export const EventImageContainer = styled.div`
  width: 136px;
  height: 107px;
  background-color: ${colors.delicateGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`

export const EventImage = styled.img`
  width: 136px;
  height: 72px;
  margin: 0 auto;
  object-fit: cover;
`
