import { useEffect, useState } from 'react'
import * as S from './styles'

import { Product, ProductPropsData, ProductVariant } from '@monorepo/interfaces'

import { Spinner } from '@monorepo/components'
import { TableBody } from '@material-ui/core'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ProductHelper } from '@monorepo/infra'

export interface SectionProductsListProps extends ProductPropsData {
  loading: boolean
  isFeaturedProducts?: boolean
}

const SectionProductsList: React.FC<SectionProductsListProps> = ({
  products,
  loading = true,
  isFeaturedProducts,
}) => {
  const [featuredChannels, setFeaturedChannels] = useState<
    string[] | null | undefined
  >()
  const [activeFeaturedChannel, setActiveFeaturedChannel] = useState<
    string | null | undefined
  >()
  const [activeFeaturedChannelProducts, setActiveFeaturedChannelProducts] =
    useState<Product[] | null | undefined>()
  const featuredChannelViewAll = 'view all'

  useEffect(() => {
    if (!isFeaturedProducts) {
      setFeaturedChannels(undefined)
      setActiveFeaturedChannel(undefined)
      setActiveFeaturedChannelProducts(undefined)
      return
    }

    if (loading || !Array.isArray(products) || !products.length) {
      return
    }

    const channels = ProductHelper.getChannels(products)

    if (Array.isArray(channels) && channels.length) {
      channels.push(featuredChannelViewAll)
      handleFeaturedChannelButtonClick(featuredChannelViewAll)
    }

    setFeaturedChannels(channels)
  }, [isFeaturedProducts, loading, products])

  const getStatus = (status: number): string => {
    switch (status) {
      case 1:
        return 'Active'
      case 2:
        return 'Draft'
      case 3:
        return 'Archived'
      case 8:
        return 'Hidden'
    }
    return 'Unknown'
  }

  const getVariantNames = (variants: ProductVariant[]): string => {
    const variantNames = variants.map((variant) =>
      ProductHelper.getVariantTitle(variant)
    )
    return variantNames.join(', ')
  }
  const { t } = useTranslation('admin')
  const history = useHistory()

  const handleEditProduct = (index: number) => {
    const id = products[index]._id
    history.push(`/products/edit/${id}`)
  }

  const handleFeaturedChannelButtonClick = (channel?: string) => {
    setActiveFeaturedChannel(channel)
    setActiveFeaturedChannelProducts(
      !!channel && channel !== featuredChannelViewAll
        ? products?.filter(
            (product) =>
              Array.isArray(product?.channels) &&
              product?.channels.findIndex(
                (item) => item?.toLowerCase() === channel.toLowerCase()
              ) !== -1
          )
        : products
    )
  }

  return (
    <S.Container>
      {isFeaturedProducts &&
      Array.isArray(featuredChannels) &&
      featuredChannels.length ? (
        <S.FeaturedChannelContainer>
          {featuredChannels.map((item) => (
            <S.FeaturedChannelButton
              key={item}
              data-active={activeFeaturedChannel === item}
              onClick={() => {
                handleFeaturedChannelButtonClick(item)
              }}
            >
              {item}
            </S.FeaturedChannelButton>
          ))}
        </S.FeaturedChannelContainer>
      ) : undefined}
      <S.TableContainer>
        <S.Table aria-label="Products Table">
          <S.TableHead>
            <S.TableRow>
              <S.TableHeadCell>
                {t('section_products_list.table_title_1')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_2')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_3')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_4')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_5')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_6')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_products_list.table_title_7')}
              </S.TableHeadCell>
            </S.TableRow>
          </S.TableHead>
          <TableBody>
            {loading ? (
              <S.TbodyRowLoading>
                <S.TBodyCellLoading scope="row">
                  <Spinner spinnerSize={8} />
                </S.TBodyCellLoading>
              </S.TbodyRowLoading>
            ) : (
              (isFeaturedProducts
                ? activeFeaturedChannelProducts
                : products
              )?.map((product: Product, index: number) => (
                <S.TbodyRow
                  key={index}
                  onClick={() => handleEditProduct(index)}
                >
                  <S.TBodyCell scope="row">
                    <S.ProductImageContainer>
                      {product.variants
                        ?.slice(0, 1)
                        ?.shift()
                        ?.media?.slice(0, 3)
                        ?.map((src, index) => (
                          <S.ProductImage
                            key={index}
                            src={src}
                            alt={product.title}
                          />
                        ))}
                    </S.ProductImageContainer>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    <S.ProductTitle>{product.title}</S.ProductTitle>
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">{product.categories[0]}</S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {getVariantNames(product.variants)}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">
                    {product.expirationDate
                      ? format(new Date(product.expirationDate), 'MM/dd/yyyy')
                      : 'N/D'}
                  </S.TBodyCell>
                  <S.TBodyCell scope="row">{product.tags}</S.TBodyCell>
                  <S.TBodyCell>
                    <S.TagsContainer>
                      {!product.expirationDate ||
                      new Date(product?.expirationDate) > new Date() ? (
                        <S.StatusTag data-status={product.status}>
                          {getStatus(product.status)}
                        </S.StatusTag>
                      ) : null}
                      {product.expirationDate &&
                      new Date(product?.expirationDate) < new Date() ? (
                        <S.StatusTag data-status={5}>Expired</S.StatusTag>
                      ) : null}
                    </S.TagsContainer>
                  </S.TBodyCell>
                </S.TbodyRow>
              ))
            )}
          </TableBody>
        </S.Table>
      </S.TableContainer>
    </S.Container>
  )
}

export default SectionProductsList
